var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:_vm.role ? 'container_is_auth' : 'container_no_auth'},[(!_vm.role)?_c('Nav'):_vm._e(),_c('div',{class:_vm.role ? 'child_is_auth' : 'child_no_auth'},[_c('v-row',{staticClass:"pa-3"},[_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-text-field',{attrs:{"solo":"","dense":"","type":"search","autocomplete":"off","label":"Search Ebook","rounded":"","append-icon":"mdi-magnify","hide-details":"","background-color":"white"},on:{"keyup":_vm.fetch},model:{value:(_vm.find),callback:function ($$v) {_vm.find=$$v},expression:"find"}})],1)],1),_c('v-divider'),(!_vm.load)?_c('div',[(_vm.ebook)?_c('section',{},[_c('h1',{staticClass:"mb-3"},[_vm._v("Article For You")]),_c('v-row',_vm._l((_vm.admin_ebook.data),function(ebook,idx){return _c('v-col',{key:idx,attrs:{"md":"3","lg":"3","sm":"6","xs":"6","cols":"12"}},[_c('v-card',{directives:[{name:"tippy",rawName:"v-tippy",value:({
                arrow: true,
                arrowType: 'round',
                animation: 'fade',
                theme: 'light',
                maxWidth: 200
              }),expression:"{\n                arrow: true,\n                arrowType: 'round',\n                animation: 'fade',\n                theme: 'light',\n                maxWidth: 200\n              }"}],staticClass:"radius-card artcle_card__ ma-2 pointer__",attrs:{"height":"270px","content":"Klik untuk melihat artikel","to":("/public/ebook/" + (ebook.id) + "/view")}},[_c('div',{staticClass:"temp_img__"},[_c('img',{staticClass:"img-fit scale",attrs:{"src":(_vm.env + "/ebook/upload/" + (ebook.user_id) + "/image/" + (ebook.image)),"height":"150px","width":"100%","alt":"cover"}})]),_c('div',{staticClass:"px-2"},[_c('h4',{directives:[{name:"snip",rawName:"v-snip",value:(1),expression:"1"}]},[_vm._v(_vm._s(ebook.judul))]),_c('span',{directives:[{name:"snip",rawName:"v-snip",value:(3),expression:"3"}],staticClass:"_isi",domProps:{"innerHTML":_vm._s(ebook.synopsis)}})])])],1)}),1),_c('div',{staticClass:"text-center pa-3"},[_c('v-pagination',{attrs:{"color":"purple","length":_vm.admin_ebook.meta.last_page},on:{"input":_vm.adminEbook},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})],1),(!_vm.ebook.data.length)?_c('section',{staticClass:"pa-2 d-flex justify-center",staticStyle:{"width":"100%"}},[_vm._m(0)]):_vm._e()],1):_vm._e()]):_vm._e(),_c('v-row',{staticClass:"pa-3"},[_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-text-field',{attrs:{"solo":"","dense":"","type":"search","autocomplete":"off","label":"Search Article","rounded":"","append-icon":"mdi-magnify","hide-details":"","background-color":"white"},on:{"keyup":_vm.fetch},model:{value:(_vm.find_b),callback:function ($$v) {_vm.find_b=$$v},expression:"find_b"}})],1)],1),(!_vm.loading)?_c('div',[(_vm.ebook)?_c('section',[_c('v-divider'),_c('h1',{staticClass:"mb-3"},[_vm._v("Other Ebook")]),_c('div',_vm._l((_vm.filteredEbook),function(ebook,idx){return _c('v-card',{directives:[{name:"tippy",rawName:"v-tippy",value:({
              arrow: true,
              arrowType: 'round',
              animation: 'fade',
              theme: 'light',
              maxWidth: 200
            }),expression:"{\n              arrow: true,\n              arrowType: 'round',\n              animation: 'fade',\n              theme: 'light',\n              maxWidth: 200\n            }"}],key:idx,staticClass:"mb-3 d-flex",attrs:{"content":"Klik untuk melihat artikel","to":("/public/ebook/" + (ebook.id) + "/view")}},[_c('div',[_c('img',{staticClass:"img-fit",attrs:{"src":(_vm.env + "/ebook/upload/" + (ebook.user_id) + "/image/" + (ebook.image)),"height":"100px","width":"100px","alt":"cover"}})]),_c('div',{staticClass:"pa-2"},[_c('h4',{directives:[{name:"snip",rawName:"v-snip",value:(2),expression:"2"}]},[_vm._v(_vm._s(ebook.judul))]),_c('span',{directives:[{name:"snip",rawName:"v-snip",value:(3),expression:"3"}],staticClass:"_isi",domProps:{"innerHTML":_vm._s(ebook.synopsis)}})])])}),1),_c('div',{staticClass:"text-center pa-3"},[_c('v-pagination',{attrs:{"color":"purple","length":_vm.ebook.meta.last_page},on:{"input":_vm.fetch},model:{value:(_vm.page_b),callback:function ($$v) {_vm.page_b=$$v},expression:"page_b"}})],1),(!_vm.ebook.data.length)?_c('section',{staticClass:"pa-2 d-flex justify-center"},[_c('img',{attrs:{"src":require("../../assets/img/404.svg"),"height":"100px","alt":"404"}}),_c('h3',[_vm._v("No Data Found!")])]):_vm._e()],1):_vm._e()]):_vm._e(),(_vm.load)?_c('div',{staticClass:"mt-5"},[_c('v-row',_vm._l((4),function(i){return _c('v-col',{key:i,attrs:{"md":"3","lg":"3","sm":"6","xs":"6"}},[_c('v-skeleton-loader',{attrs:{"type":"card"}})],1)}),1)],1):_vm._e(),(_vm.loading)?_c('div',{staticClass:"mt-5"},_vm._l((3),function(i){return _c('v-card',{key:i,staticClass:"mb-3 rounded-lg",attrs:{"flat":""}},[_c('v-skeleton-loader',{attrs:{"type":"list-item-avatar-two-line"}})],1)}),1):_vm._e()],1)],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('img',{attrs:{"src":require("../../assets/img/404.svg"),"height":"100px","alt":"404"}}),_c('h3',[_vm._v("No Data Found!")])])}]

export { render, staticRenderFns }