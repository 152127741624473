<template>
  <div :class="role ? 'container_is_auth' : 'container_no_auth'">
    <Nav v-if="!role" />
    <div :class="role ? 'child_is_auth' : 'child_no_auth'">
      <v-row class="pa-3">
        <v-col cols="12" md="4">
          <v-text-field
            solo
            dense
            type="search"
            autocomplete="off"
            label="Search Ebook"
            rounded
            append-icon="mdi-magnify"
            hide-details
            v-model="find"
            background-color="white"
            @keyup="fetch"
          ></v-text-field>
        </v-col>
      </v-row>
      <!-- <h2>All Ebooks</h2> -->
      <v-divider></v-divider>
      <div v-if="!load">
        <section class="" v-if="ebook">
          <h1 class="mb-3">Article For You</h1>

          <v-row>
            <v-col
              md="3"
              lg="3"
              sm="6"
              xs="6"
              cols="12"
              v-for="(ebook, idx) in admin_ebook.data"
              :key="idx"
            >
              <v-card
                class="radius-card artcle_card__ ma-2 pointer__"
                height="270px"
                content="Klik untuk melihat artikel"
                v-tippy="{
                  arrow: true,
                  arrowType: 'round',
                  animation: 'fade',
                  theme: 'light',
                  maxWidth: 200
                }"
                :to="`/public/ebook/${ebook.id}/view`"
              >
                <div class="temp_img__">
                  <img
                    :src="
                      `${env}/ebook/upload/${ebook.user_id}/image/${ebook.image}`
                    "
                    class="img-fit scale"
                    height="150px"
                    width="100%"
                    alt="cover"
                  />
                </div>
                <div class="px-2">
                  <h4 v-snip="1">{{ ebook.judul }}</h4>
                  <span v-snip="3" class="_isi" v-html="ebook.synopsis"> </span>
                </div>
              </v-card>
            </v-col>
          </v-row>
          <div class="text-center pa-3">
            <v-pagination
              v-model="page"
              color="purple"
              @input="adminEbook"
              :length="admin_ebook.meta.last_page"
            ></v-pagination>
          </div>
          <section
            class="pa-2 d-flex justify-center"
            style="width: 100%"
            v-if="!ebook.data.length"
          >
            <div>
              <img src="../../assets/img/404.svg" height="100px" alt="404" />
              <h3>No Data Found!</h3>
            </div>
          </section>
        </section>
      </div>
      <v-row class="pa-3">
        <v-col cols="12" md="4">
          <v-text-field
            solo
            dense
            type="search"
            autocomplete="off"
            label="Search Article"
            rounded
            append-icon="mdi-magnify"
            hide-details
            v-model="find_b"
            background-color="white"
            @keyup="fetch"
          ></v-text-field>
        </v-col>
      </v-row>
      <div v-if="!loading">
        <section v-if="ebook">
          <v-divider></v-divider>
          <h1 class="mb-3">Other Ebook</h1>
          <div>
            <v-card
              class="mb-3 d-flex"
              v-for="(ebook, idx) in filteredEbook"
              :key="idx"
              v-tippy="{
                arrow: true,
                arrowType: 'round',
                animation: 'fade',
                theme: 'light',
                maxWidth: 200
              }"
              content="Klik untuk melihat artikel"
              :to="`/public/ebook/${ebook.id}/view`"
            >
              <div>
                <img
                  :src="
                    `${env}/ebook/upload/${ebook.user_id}/image/${ebook.image}`
                  "
                  class="img-fit"
                  height="100px"
                  width="100px"
                  alt="cover"
                />
              </div>
              <div class="pa-2">
                <h4 v-snip="2">{{ ebook.judul }}</h4>
                <span v-snip="3" class="_isi" v-html="ebook.synopsis"> </span>
              </div>
            </v-card>
          </div>
          <div class="text-center pa-3">
            <v-pagination
              v-model="page_b"
              color="purple"
              @input="fetch"
              :length="ebook.meta.last_page"
            ></v-pagination>
          </div>
          <section class="pa-2 d-flex justify-center" v-if="!ebook.data.length">
            <img src="../../assets/img/404.svg" height="100px" alt="404" />
            <h3>No Data Found!</h3>
          </section>
        </section>
      </div>
      <div v-if="load" class="mt-5">
        <v-row>
          <v-col md="3" lg="3" sm="6" xs="6" v-for="i in 4" :key="i">
            <v-skeleton-loader type="card"></v-skeleton-loader>
          </v-col>
        </v-row>
      </div>
      <div v-if="loading" class="mt-5">
        <v-card v-for="i in 3" :key="i" class="mb-3 rounded-lg" flat>
          <v-skeleton-loader
            type="list-item-avatar-two-line"
          ></v-skeleton-loader>
        </v-card>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import Nav from "../../components/General/Nav.vue";

export default {
  components: { Nav },
  name: "allebook",
  data() {
    return {
      item: null,
      find: "",
      load: false,
      loading: false,
      find_b: "",
      page: 1,
      page_b: 1
    };
  },
  computed: {
    ...mapState({
      ebook: state => state.library.public_ebook,
      admin_ebook: state => state.library.public_ebook_admin,
      env: state => state.API_URL,
      id: state => state.id,
      role: state => state.role
    }),
    filteredEbook() {
      let data = null;
      if (this.ebook) {
        data = this.ebook.data.filter(el => {
          return el.visible == "public";
        });
      }
      return data;
    }
  },
  mounted() {
    this.adminEbook();
    this.fetch();
  },
  methods: {
    adminEbook() {
      this.load = true;
      this.$store
        .dispatch("library/publicEbookAdmin", {
          page: this.page,
          limit: 8,
          find: this.find
        })
        .then(() => {
          this.load = false;
        })
        .catch(() => {
          this.load = false;
        });
    },
    fetch() {
      this.loading = true;
      let data = { page: this.page_b, limit: 5, find: this.find_b };
      this.$store.dispatch("library/publicEbook", data).then(() => {
        this.loading = false;
      });
    },
    viewTo(id) {
      this.$router.push(`/ebook/${id}/view`);
    }
  }
};
</script>

<style scoped>
.container_is_auth {
  display: flex;
  background: #eceff1;
  min-height: 100vh;
  width: 75%;
  margin-left: 25%;
}
.container_no_auth {
  display: flex;
  background: #eceff1;
  min-height: 100vh;
  width: 100%;
}
.child_is_auth {
  margin-top: 100px;
  padding: 10px;
  width: 100%;
}
.child_no_auth {
  margin-top: 100px !important;
  padding: 10px;
  margin: auto;
  width: 80%;
}
._isi {
  line-height: 1.2;
}
.artcle_card__ {
  overflow: hidden;
}
.temp_img__ {
  overflow: hidden;
  min-height: 150px;
}
.scale {
  transition: 0.5s;
}
.scale:hover {
  transition: 0.5s;
  transform: scale(1.1);
}
.action__ {
  margin-top: -20px;
}
.pointer__ {
  cursor: pointer;
}
</style>
